<script setup  lang="ts">
const route = useRoute();
const localeRoute = useLocaleRoute();

function ciao() {
    if (getRouteName(route) === 'esav') {
        navigateTo('/');
    }
    else {
        // @ts-expect-error bookingId not known
        navigateTo(localeRoute({ name: 'esav', params: { bookingId: route.params.bookingId } }));
    }
};
</script>

<template>
    <button
        class="absolute left-container-gap top-5 z-20 sm:top-6 print:hidden"
        @click="ciao"
    >
        <img
            src="~~core/assets/images/jungfrau-logo.svg"
            class="w-[36px] sm:w-16 print:w-[36px]"
            alt="Jungfrau - Top of Europe"
            width="64px"
            height="45px"
        >
    </button>
</template>
